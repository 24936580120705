import React from 'react'
import { graphql } from 'gatsby'

const Doc = ({ data, pageContext, location }) => {
    const post = data.markdownRemark

    return (
        <div className='mt-6'>
            <header className='max-w-4xl mx-auto border-b border-gray-200 mb-2 sm:flex items-center justify-between px-2'>
                <h1 className='font-semibold text-xl md:text-4xl'>{post.frontmatter.title}</h1>
                <span>{post.frontmatter.date}</span>
            </header>
            <article
                className='px-2 mb-2 prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto'
                dangerouslySetInnerHTML={{ __html: post.html }}
            />
        </div>
    )
}

export default Doc

export const pageQuery = graphql`
    query DocBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`
